import React, {useState, useEffect, useContext} from 'react'

import {Entity, UserContext, EntityContext, fetchAuthenticatedContent, handleLogout} from '@parallelpublicworks/entitysync'
import {Tile, ButtonSet, Button, ToastNotification} from 'carbon-components-react'
import ListingSummary from "../../components/listing-summary"
import {getUserListings, useJobListingValidationState, validateJob, resetValidationState, } from '../../util'
import SubmitListingForm from '../../components/submit-listing-form'
import {graphql, navigate} from 'gatsby';
import { SubmitButton, ModalEntity, FieldValue } from '../../components/carbon-entitysync';
import {setAddressAndLocation} from "../../util/setAddressAndLocation";
import { InlineNotification } from 'gatsby-theme-carbon';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import recaptchaSubmit from '../../util/recaptchaSubmit'

export default function CurrentListings({data, pageContext, location}) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [userDataChangesCount, setUserDataChangesCount] = React.useState(0)
    const validationState = useJobListingValidationState();
    const [hasErrors, setHasErrors] = useState(false);
    const [modalSource, setModalSource] = useState(open && open.startsWith('local') ? { local_id: open } : open ? { id: open } : false);
    const geolocationState = useState([]);
    const [geolocation, setLocations] = geolocationState;
    const userContext = useContext(UserContext);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
      if(open && (!modalSource || (modalSource.local_id !== open && modalSource.id !== open))){
        if(open.startsWith('local')){
          setModalSource({ local_id: open });
        }
        else{
          setModalSource({ id: open });
        }
      }
    }, [open]);

    
    React.useEffect(() => {
      if (loading) {
        setUserDataChangesCount(userDataChangesCount + 1)
      }
    }, [userContext, loading])
    
    React.useEffect(() => {
      if (userDataChangesCount >= 3) {
        //all saved
        setOpen(false)
        setLoading(false)
        setUserDataChangesCount(0)
      }
    }, [userDataChangesCount])
    
    if(!location || ! Array.isArray(userContext) || userContext.length !== 2){
      return (
        <></>
      )
    }
    function pingFn(){
      return fetchAuthenticatedContent(userContext[0].auth, userContext[1], 'node/article', 'GET')
      .then(resp => {
        return resp
      })
    }

    const onSubmit = async (e, unsavedChanges, entityData) => {
      setLoading(true)
      e.persist();
      try {
        const ping = await pingFn()
        if(!ping){
          e.preventDefault();
          console.error('ping ERROR, logging out...');
          handleLogout(userContext);
          navigate('/login')
        }else{
          console.log('ping SUCCESS, continue saving...');
        }
        const valid = await recaptchaSubmit(executeRecaptcha)
        if (!valid) throw "Failed recaptcha"
        if(!validateJob(entityData, validationState, data, geolocation)){
          e.preventDefault();
          setHasErrors(true);
          let modal_content_boxes = document.querySelectorAll('.bx--modal-content');
          modal_content_boxes.forEach(function(modal_content_box) {
            modal_content_box.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          });
          setLoading(false)
        }else{
          setAddressAndLocation(unsavedChanges, geolocationState[0]);
          // setOpen(false);
        }
      } catch (err) {
        console.log(err)
      }
    };
  
    const onRequestClose = () => {
      if(!loading){
        setModalSource(false);
        setOpen(false);
        resetValidationState(validationState);
        setHasErrors(false);
      }
    };

    function ExpireButton({children, disabled}){
      async function onSubmit(e, unsavedChanges){
        setLoading(true)
        const ping = await pingFn()
        if(!ping){
          e.preventDefault();
          console.error('ping ERROR, logging out...');
          handleLogout(userContext);
          navigate('/login')
        }else{
          console.log('ping SUCCESS, continue saving...');
        }
        if(!unsavedChanges.attributes) unsavedChanges.attributes = {};
        unsavedChanges.attributes.field_expired = true;
        
      }
      return (
        <SubmitButton kind="secondary" 
        onSubmit={onSubmit} 
        disabled={loading || disabled}>
          Expire
        </SubmitButton>
      );
    }

    function EditButton({id, disabled}){
      const entityContext = useContext(EntityContext);
      let formatted_address = null;
      if(entityContext.data && entityContext.data.attributes && entityContext.data.attributes.field_address ){
        const { address_line1, locality, postal_code } = entityContext.data.attributes.field_address;
        formatted_address = `${address_line1}, ${locality}, OR ${postal_code}, USA` ;
      }
      const onOpen = () => {
        if(formatted_address){
          setLocations([{formatted_address: formatted_address}]);
        }
        setOpen(id);
      };
      return (
        <Button onClick={onOpen} 
        disabled={loading || disabled} >
          Edit
        </Button>
      );
    }


    const [{userData, currentUserId}] = userContext;
    const currentListings = getUserListings(userData, data, currentUserId);
    return (
      <>
          <ModalEntity 
            type="node--job_listing"
            source={modalSource}
            open={open}
            onRequestClose={onRequestClose}
            onSubmit={onSubmit}
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            key="modal-entity"
            primaryButtonDisabled={loading}
          >
            <h2>Editing <FieldValue field="title"/></h2>
            {hasErrors && <div className="toast-container">
              <ToastNotification
                  caption=""
                  iconDescription="Close"
                  subtitle={<span>Please check the highlighted fields and submit the form again.</span>}
                  timeout={0}
                  title="Some fields are invalid"
                  kind="error"
                  lowContrast={true}
                  onCloseButtonClick={(e) => setHasErrors(false)}
              />
              </div>}
            <SubmitListingForm key="submit-listing" validationState={validationState} geolocationState={geolocationState}/>
          </ModalEntity>
          {
             currentListings.length > 0 && currentListings.map(({id}) => {
                if(!id) return <></>;
                const source = id.startsWith('local') ? { local_id: id } : { id: id };
                return (
                    <div key={id}>
                        <Entity source={source} type="node--job_listing" componentId={id} >
                          <ListingSummary/>
                          <Tile style={{marginBottom: 40}}>
                          {id.startsWith('local') 
                          && <><InlineNotification 
                              className="inline-notification"  
                              kind="info">
                            <strong> This content will be published shortly. 
                              Please wait a few minutes and then refresh this browser page for editable job listing.</strong>
                            </InlineNotification>
                            <br/></>}
                            <ButtonSet>
                              <ExpireButton disabled={id.startsWith('local') ? true : false}/>
                              <EditButton id={id} disabled={id.startsWith('local') ? true : false} />
                            </ButtonSet>
                          </Tile>
                        </Entity>
                    </div>
                );
              })
          }
          {
            currentListings.length === 0 
            &&  <InlineNotification 
                  className="inline-notification" 
                  kind="info">
                  <strong>You have no current job listings.</strong> 
                </InlineNotification>
          }
      </>
    )
}


export const query = graphql`
query CurrentListingsQuery {
  allNodeJobListing(sort: {fields: field_publish_date, order: DESC}, filter: {changed: {gt: "2022-01-01"}, field_publish_date: {gt: "2022-01-01"}}) {
    nodes {
      drupal_id
      changed
      title
      field_expired
      field_publish_date
      field_archived
      field_company_name
      field_preferred_qualifications {
        value
      }
      field_required_qualifications {
        value
      }
      field_description {
        value
      }
      field_benefits {
        value
      }
      field_address {
        address_line1
        administrative_area
        locality
        postal_code
      }
      field_salary_number
      field_salary_number_max
      relationships {
        uid {
          drupal_id
        }
        field_category {
          drupal_id
          name
        }
        field_experience_level {
          drupal_id
          name
        }
        field_position {
          drupal_id
          name
        }
        field_salary_type {
          drupal_id
          name
          description {
            processed
          }
        }
        field_expiration {
          drupal_id
          name
        }
        field_application_method {
          drupal_id
          name
        }
        field_education {
          drupal_id
          name
        }
        field_bilingual {
          drupal_id
          name
        }
        field_experience_years {
          drupal_id
          name
        }
      }
      field_required_documents
      field_application_url {
        uri
      }
    }
  }
  allTaxonomyTermExpirationLengths {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermApplicationMethods {
    nodes {
      id
      drupal_id
      name
    }
  }
}

`;
